.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  background-color: rgba(20, 24, 33, 0.5); /* Updated to hex color with opacity */
  transition: transform 0.3s ease, opacity 0.3s ease; /* Smooth hide/show transition */
}

.header.hidden {
  transform: translateY(-100%); /* Move the header off the top of the screen */
  opacity: 0; /* Make the header invisible */
}

.header.visible {
  transform: translateY(0); /* Reset the header to its original position */
  opacity: 1; /* Make the header visible */
}

.logo img {
  display: block;
  cursor: pointer;
}

.nav-icons {
  display: flex;
  align-items: center;
  margin-right: 10%; /* Move icons 10% closer to the center */
}

.nav-icons img {
  margin-left: 20px;
  cursor: pointer;
  height: 40px; /* Ensure all icons have the same height */
}