/* src/CardComponent.css */

/* Import fonts */
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;700&family=Montserrat:wght@400;700&family=Oswald:wght@400;700&display=swap');

/* Mobile Styles */
.card-container-mobile {
  background-color: #141821;
  padding: 20px;
  border-radius: 10px;
}

.card-content-mobile {
  margin-bottom: 10px;
}

.card-title-mobile {
  font-family: 'Oswald', sans-serif;
  color: white;
  margin-bottom: 10px;
  font-size: 1.5rem;
}

.card-description-mobile {
  font-family: 'Lora', serif;
  color: white;
  margin-bottom: 10px;
  font-size: 1rem;
}

/* Desktop Styles */
.card-container {
  background-color: #141821;
  height: 75vh;
  display: flex;
  padding: 20px;
  border-radius: 10px;
  box-sizing: border-box;
}

.card-title {
  font-family: 'Oswald', sans-serif;
  color: white;
  margin-bottom: 10px;
}

.card-description {
  font-family: 'Lora', serif;
  color: white;
  margin-bottom: 20px;
}
