/* src/AppIntroSection.css */

/* Container Styles */
.app-intro-section {
  min-height: 25vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #141821;
  color: #FFFFFF;
  padding: 5px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  min-width: 66%;
}

/* Outer Content */
.outer-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: relative;
}

/* Intro Content Container */
.intro-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  max-width: 50%;
  text-align: center;
  height: 100%;
}

/* Title Styles */
.company-title {
  font-size: 1.5rem; /* Adjust font size */
  margin: 0;
  font-family: 'Oswald', sans-serif;
  z-index: 2;
}

/* Text Container */
.text-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  font-size: 3.5rem;
  font-family: 'Bangers', sans-serif;
  align-items: center;
  justify-content: center;
}

/* Video Styles */
.intro-video {
  position: absolute;
  right: 10vh;
  top: 50%;
  transform: translateY(-50%);
  height: 90%;
  width: auto;
  max-width: 200px;
  object-fit: contain;
  z-index: 1;
}

.p {
  animation: fadeInOut 2s infinite;
  color: #b8ddb1;
  font-weight: bold;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .outer-content {
    flex-direction: column; /* Stack content vertically on smaller screens */
    align-items: center;
  }

  .intro-content {
    max-width: 100%; /* Allow text to take full width */
    padding: 5px; /* Add padding for better spacing */
  }

  .company-title {
    font-size: 1.2rem; /* Smaller font size for mobile */
    margin-bottom: 5px; /* Add spacing below title */
  }

  .text-container {
    font-size: 2rem; /* Smaller font size for mobile */
    gap: 0.3rem; /* Adjust gap for smaller screens */
    padding: 0 5px; /* Add padding for better spacing */
  }

  .intro-video {
    display: none; /* Hide video on mobile devices */
  }

.app-intro-section {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #141821;
  color: #FFFFFF;
  padding: 5px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  min-width: 66%;
}
}
