/* Import fonts */
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;700&display=swap');

/* Title container */
.video-library-title-container {
  position: absolute;
  top: 80px;
  width: 100%;
  text-align: flex-start;
  z-index: 10;
}

/* Title style */
.video-library-title {
  font-family: 'Oswald', sans-serif;
  color: white;
  margin: 0;
  padding-left: 10px;
}

/* Subtitle container */
.video-library-subtitle-container {
  position: absolute;
  top: 140px;
  left: 5px;
  width: 10%;
  text-align: flex-start;
  z-index: 10;
}

/* Subtitle style */
.video-library-subtitle {
  font-family: 'Lora', serif;
  color: white;
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
  padding-left: 10px;
}

/* Mobile Styles */
.video-library-title-container-mobile {
  background-color: #141821;
  padding: 10px;
  text-align: center;
}

.video-library-title-mobile {
  font-family: 'Oswald', sans-serif;
  color: white;
  margin: 0;
  font-size: 1.5rem;
}

.video-library-subtitle-mobile {
  font-family: 'Lora', serif;
  color: white;
  font-size: 1rem;
  margin: 5px 0 0 0;
}
