/* src/components/Support.css */

.support-section {
    padding: 40px;
    background-color: #141821;
    color: #ffffff;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .support-content {
    margin-top: 20px;
  }
  
  .support-info,
  .support-services,
  .support-policies,
  .support-delete-account {
    margin-bottom: 30px;
  }
  
  .support-info h3,
  .support-services h3,
  .support-policies h3,
  .support-delete-account h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .support-services ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .support-services li {
    margin-bottom: 5px;
  }
  
  .support-delete-account ol {
    padding-left: 20px;
    list-style-type: decimal;
  }
  
  a {
    color: #00ff00;
    text-decoration: underline;
  }
  