/* src/components/AuthenticatorOverrides.css */

/* Override Amplify CSS Variables */
:root {
    /* Primary Colors */
    --amplify-primary-color: #212324; /* Primary color */
    --amplify-primary-contrast: #FFFFFF; /* Text color on primary buttons */
  
    /* Background Colors */
    --amplify-background-color: #0c0c0c;
  
    /* Text Colors */
    --amplify-text-color: #0e0e0f; /* General text color */
    --amplify-error-color: #FF0000; /* Error text color */
    ----amplify-header-background-color: #0c0c0c;
  
    /* Button Styles */
    --amplify-button-primary-background-color: #345e50;
    --amplify-button-primary-text-color: #FFFFFF;
    --amplify-button-primary-hover-background-color: #1976D2;
  
    --amplify-button-secondary-background-color: #FFFFFF;
    --amplify-button-secondary-text-color: #2196F3;
    --amplify-button-secondary-hover-background-color: #E3F2FD;
  
    /* Input Field Styles */
    --amplify-input-border-color: #0d0d0d;
    --amplify-input-focus-border-color: #0c0c0c;
    --amplify-input-text-color: #000000;
  }
  
  /* Specific Component Overrides */
  
  /* Button Customization */
  .amplify-button {
    border-radius: 5px; /* Rounded corners */
    padding: 10px 20px; /* Increased padding */
    font-size: 1em; /* Consistent font size */
    transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions */
    background-color: #5d7e73;
    color: #ffffff; /* Change text color to white */
    font-weight: bold; /* Make the text bold */
  }

  .amplify-text {
    color: white;
  }
  
  .amplify-button:hover {
    transform: translateY(-2px); /* Slight lift on hover */
  }
  
  /* Input Field Customization */
  .amplify-textfield input {
    border: 1px solid var(--amplify-input-border-color);
    border-radius: 5px; /* Rounded corners */
    padding: 10px; /* Increased padding */
    color: var(--amplify-input-text-color);
    font-size: 1em; /* Consistent font size */
    transition: border-color 0.3s ease; /* Smooth border transition */
  }
  
  .amplify-textfield input:focus {
    border-color: var(--amplify-input-focus-border-color);
    outline: none; /* Remove default outline */
    box-shadow: 0 0 5px var(--amplify-primary-color); /* Add subtle shadow */
  }
  
  
  /* Error Message Customization */
  .amplify-field-error-message {
    color: var(--amplify-error-color);
    font-size: 0.9em; /* Slightly smaller font size */
  }
  
  /* Link Customization */
  .amplify-form-footer-link {
    color: var(--amplify-primary-color);
    text-decoration: none; /* Remove underline */
    font-weight: bold; /* Bold text */
  }
  
  .amplify-form-footer-link:hover {
    text-decoration: underline; /* Underline on hover */
  }

  .amplify-form {
    background-color: #7ac9af;
  }
  