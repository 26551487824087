/* src/components/Login.css */

.login-container {
    /* Set the background image */
    background-image: url('../../public/images/thinGradient.png'); /* Path is relative to the public folder */
    background-size: cover; /* Ensure the image covers the entire container */
    background-repeat: no-repeat; /* Prevent image repetition */
    background-position: center; /* Center the background image */
  
    /* Set the container to occupy the full viewport height and width */
    height: 100vh;
    width: 100vw;
  
    /* Use Flexbox to center the content both vertically and horizontally */
    display: flex;
    justify-content: center; /* Horizontally center */
    align-items: center; /* Vertically center */
  }
  
  .authenticator-wrapper {
    /* Optional: Additional styling for the Authenticator component */
    background-color: rgba(255, 255, 255, 0.85); /* Semi-transparent white background */
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 550px; /* Fixed width for the login box */
  }
  
  .amplify-textfield input {
    background-color: #FFFFFF; /* White background for inputs */
    color: #000000; /* Black text */
  }
  
  @media (max-width: 400px) {
    .authenticator-wrapper {
      width: 90%; /* Responsive width for smaller screens */
      padding: 20px;
    }
  }
  