/* src/QRCodeSection.css */

/* Styles for the QRCodeSection */
#qr-code-section {
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #FFFFFF;
    padding: 20px 40px;
  }
  
  /* Left Side Styles */
  .qr-left {
    flex: 1;
    text-align: center;
  }

  .support-link {
    color: white; /* Change the link color to white */
    text-decoration: none; /* Optional: Remove underline */
  }
  
  .qr-left h3 {
    margin-top: 5vh;
  }
  
  .qr-left p a {
    color: #FFFFFF;
    text-decoration: underline;
  }
  
  /* Right Side Styles */
  .qr-right {
    flex: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 20px;
  }
  
  .qr-code-image {
    width: 250px;
    height: 250px;
  }
  
  .download-link {
    color: #00ff00;
    text-decoration: underline;
    font-size: 1.2rem;
  }
  
  /* Mobile Styles */
  @media (max-width: 768px) {
    #qr-code-section {
      padding: 10px; /* Adjust padding for mobile */
      flex-direction: column; /* Stack items vertically on mobile */
      height: auto; /* Let height adjust automatically */
    }
  
    .qr-left {
      font-size: 0.9rem; /* Make text smaller on mobile */
    }
  
    .qr-right {
      display: none; /* Hide the right div on mobile */
    }
  
    .qr-code-image {
      width: 150px;
      height: 150px; /* Make the QR code smaller on mobile */
    }
  
    .download-link {
      font-size: 1rem; /* Adjust font size */
    }
  }
  